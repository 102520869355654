import React, {useContext} from 'react'
import { Router } from '@reach/router'
import ApolloContext from '../../context/ApolloContext'
import Seo from "../../components/seo"

import Project from './Project'
import PostTemplate from './PostTemplate'

function Archives(props) {
	const apollo = useContext(ApolloContext)

	// Find the right single post, save its position in array
	let newsI = null
	const News = apollo.data?.News.nodes.filter(function(key,i) {
		if (key.slug === props.params['*']) {
			newsI = i
			return true
		}
		return false
	})[0]

	// Find out prev/next post links
	let nextPostLink, prevPostLink
	if(newsI > 0) {
		prevPostLink = `/blog${apollo.data?.News.nodes[newsI - 1].uri}`
	}
	if(newsI < apollo.data?.News.nodes.length - 1) {
		nextPostLink = `/blog${apollo.data?.News.nodes[newsI + 1].uri}`
	}


	const academics = apollo.data?.academics.nodes.filter(function(key) {
		return key.slug === props.params['*'].replace('portfolio/','')
	})[0]

	
	let SinglePost = () => {
		
		if(News) 
			return <>
				<Seo title={News.title ? News.title : 'title'} />
				<PostTemplate post={News} prevPostLink={prevPostLink} nextPostLink={nextPostLink} />
			</>
			
		return null
	}

	let SinglePostPortfolio = () => {

		if(academics) {
			return <>
				<Seo title={academics.title ? academics.title : 'title'} />
				<Project key={academics.id} {...academics} />
			</>
		}
		
		return null
	}

	return (
		<div className="routers">
			<Router>
				<SinglePost path="/blog/:id" />
				<SinglePostPortfolio path="/portfolio/:id" />
			</Router>
		</div>
	)
}

export default Archives