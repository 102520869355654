import React, { useContext } from "react"
import Archivez from '../apollo/archives'
import { graphql } from "gatsby"

import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import ReactHtmlParser from 'react-html-parser'

import Footer from '../components/Footer'
import FormDetail from '../components/Content/ACF/Custom/FormDetail'


function Portfolio(props) {

	return (
    <>
      <main className={`c0 main-portfolio lang-${props.data?.wpGeneralSettings?.generalSettings?.language}`}>

        {props.data?.wpPage?.yoast_head ?
          <Helmet>{ ReactHtmlParser(props.data?.wpPage?.yoast_head) }</Helmet>
        :
          <Seo title={props.data?.wpPage?.title ? props.data?.wpPage?.title : 'title'} description={props.data?.wpGeneralSettings?.generalSettings?.description} siteTitle={props.data?.wpGeneralSettings?.generalSettings?.title} />
        }

				<Archivez {...props} />

				<div id="block-newsletter" className="block c1 block-page_Pagebuilder_Sections_Content_Block_Custom newsletter">
					<FormDetail />
				</div>

        {/* { props.data?.wpPage.content ?
          <WPDefault key="WP-default-1" { ...props.data? } />
        : null } */}

        <Footer/>
  
      </main>
    </>
	)
}

export default Portfolio

export const query = graphql`
  query {
    ...wpNaviPrimary
    ...wpGeneralSettings
  }
`