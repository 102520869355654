import React from 'react'
import './style.scss'
import { Link } from 'gatsby'

import Intro from '../../../animations/Intro'
import VideoHTML from '../../../components/Video/HTML'

function PostTemplate(props) {

	const post = props.post
	const d = new Date(post.date)

	const galleries = post.news?.blocks?.map((node, i) => {
		const videoFile = node.video?.mediaItemUrl

		if (node.type === 'image' && node.img) {
			return (
				<div key={i} className={`portfolio-image padd-right padd-bottom ${node.classes} ${node.size}`}>
					<div className="image-wrapper">
							<img className="img-cover" srcSet={node.img?.srcSet} />
					</div>
				</div>
			)
		}

		if (node.type === 'wysiwyg' && props.content) {
			return (
				<div key={i} className={`portfolio-wysiwyg padd-top padd-right padd-bottom-2 ${node.classes} ${node.size}`}>
					<div dangerouslySetInnerHTML={{__html: props.content}}/>
				</div>
			)
		}

		if (node.type === 'video' && videoFile) {
			return (
				<div key={i} className={`portfolio-video padd-right padd-bottom ${node.classes} ${node.size}`}>
					<VideoHTML file={videoFile} />
				</div>
			)
		}

		return false
	})

	return (
		<Intro id="post-template" visible={true} in={{fadeInBottom: 500}} delayIn={0} mounted={true} stay={true}>

			<div className="padd">
				<h1>{post.title}</h1>
				<div className="c0 t img-featured">
					<div className="box-top text-right font2">
						{d.toLocaleDateString([], {day: '2-digit', month:'2-digit', year: '2-digit'})}
					</div>
					<div className="aspect-ratio">
						<img className="img-cover" srcSet={post.featuredImage?.node.srcSet} />
					</div>
				</div>
				<div className="c0 padd-top-2 padd-bottom-2 grid-12">
					<div className="content span-6 span-9-tablet span-12-mobile">
						<div dangerouslySetInnerHTML={{__html: post.content}}/>
					</div>
				</div>
			</div>

			{ galleries ?
				<div className="c0 padd-top padd-left flex-12">
					{galleries}
				</div>
			: null }

			{props.prevPostLink || props.nextPostLink ?
				<div className="links">
					{ props.prevPostLink ?
						<Link to={props.prevPostLink} className="link-prev">Previous Post</Link>
					: null }

					{ props.nextPostLink ?
						<Link to={props.nextPostLink} className="link-next">Next Post</Link>
					: null }
				</div>
			: null }

		</Intro>
	)
}

export default PostTemplate