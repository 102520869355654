import React, {useContext} from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import VideoHTML from '../../../components/Video/HTML'

import FSLightBoxContext from '../../../context/FSLightBoxContext'

function Project(props) {
	const lightBoxContext = useContext(FSLightBoxContext)

	const website = props.portfolio.website


	function thumbClicked(lightboxSlidesArray) {
		lightBoxContext.setSources(() => lightboxSlidesArray)
		setTimeout(() => {
			lightBoxContext.setToggler((toggle) => !toggle)
		}, 50)
	}

	const galleries = props.portfolio?.blocks?.map((node, i) => {
		const videoFile = node.video?.mediaItemUrl

		if (node.type === 'image' && node.img) {
			return (
				<div key={i} className={`hover-trigger portfolio-image padd-right padd-bottom ${node.classes} ${node.size}`} onClick={() => thumbClicked([node.img?.sourceUrl])} aria-label="Lightbox trigger" onKeyDown={() => thumbClicked([node.img?.sourceUrl])} role="button" tabIndex={0}>
					<div className="image-wrapper">
							<img className="img-cover" srcSet={node.img?.srcSet} />
					</div>
				</div>
			)
		}

		if (node.type === 'wysiwyg' && props.content) {
			return (
				<div key={i} className={`portfolio-wysiwyg padd-top padd-right padd-bottom-2 ${node.classes} ${node.size}`}>
					<div dangerouslySetInnerHTML={{__html: props.content}}/>
				</div>
			)
		}

		if (node.type === 'video' && videoFile) {
			return (
				<div key={i} className={`portfolio-video padd-right padd-bottom ${node.classes} ${node.size}`}>
					<VideoHTML file={videoFile} />
				</div>
			)
		}

		return false
	})

	return (
		<Intro visible={true} in={{fadeInBottom: 500}} delayIn={0} mounted={true} stay={true} className={``}>
			
			<div className="c5 img-featured">
				<div className="aspect-ratio">
					<img className="img-cover" srcSet={props.featuredImage?.node.srcSet} />
				</div>
			</div>

			<div className="c5 padd padd-top-4 padd-bottom-4 grid-12">

				<div className="content padd-top-4 padd-bottom-4 span-6 span-9-tablet span-12-mobile">
					<h1>{props.title}</h1>
					<div dangerouslySetInnerHTML={{__html: props.content}}/>
				</div>

				{ website ?
				<div className="content span-12">
					<p className="text-right font2"><a href={website} target="_blank">View Website</a></p>
				</div>
				: null }

			</div>

			{ galleries ?
				<div className="c0 padd-top padd-left flex-12">
					{galleries}
				</div>
			: null }

		</Intro>
	)
}

export default Project